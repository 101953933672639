import React, { useContext } from "react";
import "./PaymentMethod.css";
import TitleText from "src/components/TitleText/TitleText";
import SubText from "src/components/SubText/SubText";
import InputText from "src/components/InputText/InputText";
import PrincipalButton from "src/components/PrincipalButton/PrincipalButton";
import { InputMessage } from "src/models/inputMessage";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useNavigate, useLocation } from "react-router-dom";
import { PromisePageContext } from "src/machines/pageMachine";
import axiosInstance from "src/axiosInstance";

const PaymentMethod = () => {
  const location = useLocation();
  const { chargerId } = location.state || {};
  let chargerSerialId = chargerId; // Charger Id passed from Link so will be available directly.
  if (chargerSerialId === null || chargerSerialId === undefined) {
    chargerSerialId = localStorage.getItem("chargerId");
  }
  const [cardNumberStatus, setCardNumberStatus] = React.useState<boolean>(true);
  const [expirationStatus, setExpirationStatus] = React.useState<boolean>(true);
  const [cvvStatus, setCvvStatus] = React.useState<boolean>(true);

  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [promoCode, setPromoCode] = React.useState<string>("");
  const [promoMessages, setPromoMessages] = React.useState<InputMessage[]>([]);
  const { pageSend } = useContext(PromisePageContext);

  const makePayment = async () => {
    await axiosInstance
      .post(`/user/send-cache-promocode`, {
        chargerId: chargerSerialId,
        promoCode: promoCode,
      })
      .then((response) => {})
      .then((data) => {
        pageSend({
          type: "CHANGE_INVOICE",
          data: { invoice: { promoCode: promoCode } },
        });
        navigate("/Congrats", { state: { chargerId: chargerSerialId } });
      })
      .catch((error) => {
        navigate("/404", { state: { chargerId: chargerSerialId } });
      });
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#141D2F",
        fontWeight: "400",
        fontFamily: "CustomPoppins, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#141D2F",
        },
        "::placeholder": {
          color: "#85898F",
        },
      },
    },
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (promoCode !== "") {
      const validPromoCodes = ["DISCOUNTIO", "GCXPO"];
      if (!validPromoCodes.includes(promoCode.toUpperCase())) {
        setPromoMessages([{ message: "Promo Code invalid" }]);
        return;
      }
      makePayment();
      return;
    }

    if (cardNumberStatus || expirationStatus || cvvStatus) {
      setErrorMessage("Please fill all the fields");
      return;
    }
    await axiosInstance
      .post(`/user/create-payment-intent`, {
        chargerId: chargerSerialId,
        promoCode: promoCode,
      })
      .then((response) => {
        confirmCardPayment(stripe, elements, response.data.clientSecret);
      })
      .catch((error) => {
        navigate("/404", { state: { chargerId: chargerSerialId } });
      });
    if (!stripe || !elements) {
      return;
    }
    const confirmCardPayment = (
      stripe: any,
      elements: any,
      clientSecret: any,
    ) => {
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement) as any,
          },
        })
        .then((result: any) => {
          if (result.error) {
            navigate("/404", { state: { chargerId: chargerSerialId } });
          } else {
            // The payment has been processed!
            if (result.paymentIntent?.status === "requires_capture") {
              pageSend({
                type: "CHANGE_INVOICE",
                data: { invoice: { promoCode: "" } },
              });
              navigate("/Congrats", { state: { chargerId: chargerSerialId } });
            }
          }
        });
    };
  };

  return (
    <div className="PaymentMethod-layout">
      <TitleText
        text="Add payment method"
        styles={{ marginBottom: "0" }}
      ></TitleText>
      <SubText
        text="Please add your credit card information."
        styles={{ marginBottom: "40px" }}
      ></SubText>
      <form onSubmit={handleSubmit} className="formPaymentMethod">
        <InputText
          messages={promoMessages}
          value={promoCode}
          setValue={setPromoCode}
          type="text"
          label="Add Promo Code"
          placeholder="Promo Code"
          styles={{ marginTop: "15px", marginBottom: "15px" }}
        />

        {!(promoCode !== "") && (
          <>
            <label className="label-payment">Card Number</label>
            <div
              style={{
                border: "1px solid #E7E7E9",
                borderRadius: "10px",
                padding: "20px 4px",
                marginBottom: "12px",
              }}
            >
              <CardNumberElement
                onChange={(e) => {
                  setCardNumberStatus(e.empty);
                }}
                options={CARD_ELEMENT_OPTIONS}
              ></CardNumberElement>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "45%" }}>
                <label className="label-payment">Expiration</label>
                <div
                  style={{
                    border: "1px solid #E7E7E9",
                    borderRadius: "10px",
                    padding: "20px 4px",
                    marginBottom: "12px",
                  }}
                >
                  <CardExpiryElement
                    onChange={(e) => {
                      setExpirationStatus(e.empty);
                    }}
                    options={CARD_ELEMENT_OPTIONS}
                  ></CardExpiryElement>
                </div>
              </div>
              <div style={{ width: "45%" }}>
                <label className="label-payment">CVC</label>
                <div
                  style={{
                    border: "1px solid #E7E7E9",
                    borderRadius: "10px",
                    padding: "20px 4px",
                    marginBottom: "12px",
                  }}
                >
                  <CardCvcElement
                    onChange={(e) => {
                      setCvvStatus(e.empty);
                    }}
                    options={CARD_ELEMENT_OPTIONS}
                  ></CardCvcElement>
                </div>
              </div>
            </div>
          </>
        )}

        {errorMessage !== "" && (
          <div className="error-Message">
            <span>{errorMessage}</span>
          </div>
        )}

        <PrincipalButton
          type="submit"
          text="Add"
          version="blue"
          chargerId={chargerSerialId}
          styles={{ marginBottom: "53px", marginTop: "15px", width: "100%" }}
        ></PrincipalButton>
      </form>
    </div>
  );
};

export default PaymentMethod;
