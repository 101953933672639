import "./Login.css";

import InputText from "../../components/InputText/InputText";
import TitleText from "../../components/TitleText/TitleText";
import SubText from "../../components/SubText/SubText";
import PrincipalButton from "../../components/PrincipalButton/PrincipalButton";
import Google from "../../icons/Google";
import SocialMediaButton from "../../components/SocialMediaButton/SocialMediaButton";
import Facebook from "../../icons/Facebook";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axiosInstance from "src/axiosInstance";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const apiServiceURL = process.env.REACT_APP_API_URL as string;
  const location = useLocation();

  const { chargerId } = location.state || {};
  let chargerSerialId = chargerId; // Charger Id passed from Link so will be available directly.
  // Save chargerId in localStorage when available (only the first time)
  useEffect(() => {
    if (chargerId) {
      localStorage.setItem("chargerId", chargerId);
    }
  }, [chargerId]);
  const navigate = useNavigate();

  const handleLogin = async (event: any) => {
    event.preventDefault();

    // Send the login request using the axiosInstance
    await axiosInstance
      .post("/auth/login", {
        email,
        password,
      })
      .then(function (response) {
        if (response.status === 200) {
          // Navigate to the home page (or wherever needed)
          navigate("/PaymentMethod", { state: { chargerId: chargerSerialId } });
        }
      })
      .catch(function (error) {
        const status = error.response.status;
        switch (status) {
          case 401:
            setError("Email or password incorrect.");
            break;
          case 503:
            setError("Server unavailable. Pleae try again later.");
            break;
          case 500:
            setError("Internal server error. Please try again later.");
        }
      });
  };

  const handleGoogleLogin = () => {
    window.location.href = apiServiceURL + "/oauth2/authorization/google";
  };
  const handleFacebookLogin = () => {
    window.location.href = apiServiceURL + "/oauth2/authorization/facebook";
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    const expiresIn = queryParams.get("expiresIn");

    if (token && expiresIn) {
      localStorage.setItem("token", token);
      localStorage.setItem("tokenExpiry", expiresIn);
      let charger = localStorage.getItem("chargerId");
      navigate("/PaymentMethod", { state: { chargerId: charger } });
    }
  }, [navigate]);

  return (
    <div className="Login-layout">
      <form onSubmit={handleLogin}>
        <TitleText
          text="Welcome back 👋"
          styles={{ marginBottom: "40px" }}
        ></TitleText>
        <br></br>
        <SubText
          text="Enter your email address and password to access your account."
          styles={{ marginBottom: "54px" }}
        ></SubText>
        <InputText
          type="email"
          value={email}
          setValue={setEmail}
          label="Email"
          placeholder="hello@ilovedrivinggreen.com"
          styles={{ marginBottom: "31px" }}
        />
        <InputText
          type="password"
          value={password}
          setValue={setPassword}
          label="Password"
          placeholder="*******"
          styles={{ marginBottom: "20px" }}
        />

        {error && <p style={{ color: "red", marginBottom: "20px" }}>{error}</p>}

        <PrincipalButton
          type="submit"
          text="Sign in"
          version="blue"
          styles={{ marginBottom: "53px" }}
          chargerId={chargerSerialId}
        ></PrincipalButton>
      </form>
      <div className="LoginTextSide" style={{ marginBottom: "35px" }}>
        <div></div>
        <span>Or Sign in with</span>
        <div></div>
      </div>

      <div className="Login-Buttons" style={{ marginBottom: "54px" }}>
        <SocialMediaButton onClick={handleGoogleLogin} >
          <Google />
          <span>with Google</span>
        </SocialMediaButton>
        <SocialMediaButton onClick={handleFacebookLogin}>
          <Facebook />
          <span>with Facebook</span>
        </SocialMediaButton>
      </div>

      <div className="New-Login" style={{ textAlign: "center" }}>
        <span style={{ margin: "18px" }}>Don't have an account?</span>
        <br></br>
        <b>
          <Link to="/SignUp" state={{ chargerId }}>
            Register Account
          </Link>
        </b>
        <span style={{ margin: "8px" }}>or</span>
        <b>
          <Link to="/GuestPaymentMethod" state={{ chargerId }}>
            Continue as Guest
          </Link>
        </b>
      </div>
    </div>
  );
};

export default Login;
