import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

//styles
import "./SplashScreen.css";
import PrincipalButton from "../../components/PrincipalButton/PrincipalButton";
import axiosInstance from "src/axiosInstance";

const Splashscreen = () => {
  const { chargerId } = useParams();
  const [chargerIdState] = useState(chargerId);
  let chargerSerialId = chargerIdState;

  if (chargerSerialId && chargerSerialId !== "chargerId") {
    localStorage.setItem("chargerId", chargerSerialId);
  }
  const navigate = useNavigate();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await axiosInstance.get("/auth/check");
        // Handle the JSON response
        if (response.data.status === "success") {
          navigate("/Home", { state: { chargerId: chargerSerialId } });
        } else {
          setAuthChecked(true);
        }
      } catch (error) {
        setAuthChecked(true);
      }
    };
    checkAuthStatus();
  });

  if (!authChecked) {
    return null;
  }

  return (
    <div className="SplashScreen-page">
      <img className="Logo" src="/image/logo.png" alt="Logo site"></img>
      <span>EV Charging in Condos made Simple</span>
      <img
        className="SplashScreen-image"
        src="/image/SplashscreenAnimate.gif"
        alt="Splashscreen"
      ></img>
      <p>
        By signing up, you agree with our friendly
        <a href="https://jplug.io" target="_blank" rel="noopener noreferrer">
          <b> terms and conditions</b>
        </a>
        .
      </p>

      <PrincipalButton
        redirect="/Login"
        text="Get Started"
        version="white"
        chargerId={chargerIdState}
      ></PrincipalButton>
    </div>
  );
};

export default Splashscreen;
