import React from "react";

//style
import "./SocialMediaButton.css";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
}

const SocialMediaButton = ({ children, onClick }: Props) => {
  return (
    <button className="SocialMediaButton-Component" onClick={onClick}>
      {children}
    </button>
  );
};

export default SocialMediaButton;
